<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="会员名称" prop="name" >
        <a-input v-model="form.name" placeholder="请输入会员名称" />
      </a-form-model-item>
      <a-form-model-item label="会员logo" prop="logo" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          v-model="form.logo"
          :showUploadList="false"
          :beforeUpload='(info) => fileBeforeUpload("logo",info)'
          :customRequest='(info) => fileUploadRequest("logo",info)'
        >
          <img v-if="form.logo" :src="form.logo" alt="logo"
               style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="会员时长(月)" prop="month" >

        <a-input-number style='width: 150px' v-model='form.month' placeholder='请输入会员月份时长' :min='1' :maxLength='10' :max='9999' />
      </a-form-model-item>
      <a-form-model-item label="实际价格" prop="realPrice" >
        <a-input-number v-model='form.realPrice' placeholder='请输入实际价格' :min='0.01' :max='9999999' />
      </a-form-model-item>

      <a-form-model-item label="总价" prop="totalPrice" >
        <a-input-number v-model='form.totalPrice' placeholder='请输入划线价' :min='0.01' :max='9999999' />
      </a-form-model-item>
      <a-form-model-item label="会员app背景" prop="bgImg" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          v-model="form.bgImg"
          :showUploadList="false"
          :beforeUpload='(info) => fileBeforeUpload("bgImg",info)'
          :customRequest='(info) => fileUploadRequest("bgImg",info)'
        >
          <img v-if="form.bgImg" :src="form.bgImg" alt="bgImg"
               style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="会员描述" prop="content" >
        <a-textarea v-model="form.content" :row='4'/>
      </a-form-model-item>

      <a-form-model-item style="display: flex" label="用户名色值" prop="userNameColor" >
        <el-color-picker style="margin-left: 100%"  v-model="form.userNameColor" />
      </a-form-model-item>
      <a-form-model-item style="display: flex" label="会员时长色值" prop="vipDateColor" >
        <el-color-picker style="margin-left: 100%"  v-model="form.vipDateColor" />
      </a-form-model-item>
      <a-form-model-item style="display: flex" label="会员描述色值" prop="vipContentColor" >
        <el-color-picker style="margin-left: 100%"  v-model="form.vipContentColor" />
      </a-form-model-item>
      <a-form-model-item label="ios内购产品id" prop="productId" >
        <a-input v-model="form.productId" placeholder="请输入ios内购产品id" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getMenu, addMenu, updateMenu } from '@/api/vip/menu'
import Editor from '@/components/Editor'
import elColorPicker from 'el-color-picker'
import UploadFileToOSS from '@/utils/upload-file-to-oss'
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor,elColorPicker
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      loading:false,
      // 表单参数
      form: {
        id: null,

        name: null,

        logo: null,

        month: null,

        realPrice: null,

        productId: null,

        isOpen: null,

        totalPrice: null,

        bgImg: null,

        content: null,

        userNameColor: "",

        vipDateColor: "",

        vipContentColor: "",

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name:[
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ],
        logo:[
          { required: true, message: 'logo不能为空', trigger: 'blur' }
        ],
        month:[
          { required: true, message: '会员时长不能为空', trigger: 'blur' }
        ],
        realPrice: [
          { required: true, message: '实际价格不能为空', trigger: 'blur' }
        ],
        isOpen: [
          { required: true, message: '是否启用不能为空', trigger: 'blur' }
        ],
        totalPrice: [
          { required: true, message: '总价不能为空', trigger: 'blur' }
        ],
        bgImg:[
          { required: true, message: 'app背景图不能为空', trigger: 'blur' }
        ],
        content:[
          { required: true, message: '会员描述不能为空', trigger: 'blur' }
        ],
        userNameColor: [
          { required: true, message: '用户名称色值不能为空', trigger: 'blur' }
        ],
        vipDateColor: [
          { required: true, message: '会员日期色值不能为空', trigger: 'blur' }
        ],
        vipContentColor: [
          { required: true, message: 'vip内容色值不能为空', trigger: 'blur' }
        ],

      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        name: null,
        logo: null,
        month: null,
        realPrice: null,
        productId: null,
        isOpen: null,
        totalPrice: null,
        bgImg: null,
        content: null,
        userNameColor: '',
        vipDateColor: '',
        vipContentColor: '',
        createTime: null,
        remark: null,
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getMenu({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /**
     * 文件上传前校验
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileBeforeUpload(field,fileInfo) {
      // 图片类型字段, 进行文件类型判断
      if (!fileInfo.type.includes('image')) {
        this.$message.warning('请上传图片');
        return false;
      }
      return true;
    },
    /**
     * 文件上传请求自定义
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileUploadRequest(field,fileInfo) {

      this.loading = true;
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {

          this.loading = false;
          // this.form.imgUrl = res[0].url;
          this.form[field] = res[0].url;
        },
      });
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateMenu(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addMenu(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
